import { useStaticQuery, graphql } from 'gatsby'

//TODO subscriptionForAmbassadors returns null. Don't know why
const useSubscriptionsSettings = () => {
  const data = useStaticQuery(graphql`
    query SubscriptionsSettings {
      allContentfulProduct {
        edges {
          node {
            name
            sku
            subscription {
              discountPercentage
              name
              intervals {
                name
                length
                unit
                id
              }
            }
            subscriptionForAmbassadors {
              name
              discountPercentage
              name
              intervals {
                name
                length
                unit
                id
              }
            }
            variants {
              name
              sku
            }
          }
        }
      }
    }
  `)
  const settings = []
  data.allContentfulProduct.edges.map(item => {
    const product = item.node
    settings.push({
      name: product.name,
      sku: product.sku,
      subscription: product.subscription
    })
  })
  return settings
}

export default useSubscriptionsSettings
