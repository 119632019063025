import Dropdown from 'react-bootstrap/Dropdown'
import React, { useState } from 'react'

const CartQuantityProduct = ({ cartItem, handleQuantity }) => {
  const product = cartItem.variant
  const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [selectedQty, setSelectedQty] = useState(cartItem.quantity)

  const updateQuantity = qty => {
    setSelectedQty(qty)
    handleQuantity(cartItem.id, qty)
  }

  if (product === null) {
    return <p>No product found</p>
  }
  return (
    <>
      <div className="d-none d-md-block mb-3 fw-bold">QUANTITY</div>
      <Dropdown className="custom-dropdown">
        <Dropdown.Toggle className="border border-gray-400 border rounded-5 bg-yellow-30  dropdown_mobile_sizes">
          {selectedQty}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {quantities.map((quantity, i) => {
            return (
              <Dropdown.Item
                active={cartItem.quantity === quantity}
                key={i}
                onClick={() => updateQuantity(quantity)}
                className="d-flex fontSFPro-regular txt-gray-900 align-items-center justify-content-center fs-16"
              >
                {quantity}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default CartQuantityProduct
