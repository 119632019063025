import Metadata from '~/components/Metadata'
import React from 'react'
import Layout from '~/components/Layout'
import CartPage from '~/components/Cart/CartPage'

const Cart = () => {
  return (
    <Layout>
      <Metadata title="Cart" />
      <CartPage />
    </Layout>
  )
}

export default Cart
