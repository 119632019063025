import React, { useEffect, useState } from 'react'
import { getCartAdjustments, getPromotions } from '~/utils/cart'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import Loader from '~/components/Loader'
import CartPageItem from '~/components/Cart/CartPageItem'
import CartPromoCode from '~/components/Cart/CartPromoCode'
import { navigate, Link } from 'gatsby'
import { FaShare } from 'react-icons/fa'

const windowGlobal = typeof window !== 'undefined' && window

const CartPage = () => {
  const chordCommerce = useCart()
  const cartData = chordCommerce.cart.data
  const [cartItems, setCartItems] = useState([])
  const [promotions, setPromotions] = useState([])
  const [cartAmountAdjustments, setCartAmountAdjustments] = useState([])
  const [showCopyMessage, setShowCopyMessage] = useState(false)

  const parsedUrl = windowGlobal && new URL(window.location.href)

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    return () => {
      setCartItems([])
      setPromotions([])
      setCartAmountAdjustments([])
    }
  }, [])

  useEffect(() => {
    if (cartItems.length > 0) {
      setCartAmountAdjustments(getCartAdjustments(cartItems))
      setPromotions(getPromotions(cartItems))
    }

    return () => {}
  }, [cartItems])

  useEffect(() => {
    let currentCartItems = []
    if (!chordCommerce.cart.isFetching) {
      currentCartItems = chordCommerce.cart.data.lineItems !== null ? chordCommerce.cart.data.lineItems : []
      setCartItems(currentCartItems)

      const sharedSKUs = getSharedSKUs()
      if (sharedSKUs.length > 0) {
        async function updateCart() {
          try {
            await chordCommerce.modifyCart({
              lineItemsAttributes: sharedSKUs
            })
          } catch (error) {}
          await chordCommerce.loadCart()
        }
        updateCart()

        navigate('/cart')
      }
    }
  }, [chordCommerce.cart])

  const removeItem = async lineItemId => {
    try {
      await chordCommerce.removeFromCart(lineItemId)
    } catch (error) {}
  }

  const updateQuantity = async (cartItemId, quantity) => {
    try {
      await chordCommerce.modifyQuantity(cartItemId, quantity)
    } catch (error) {}
  }

  const updateSubscription = async (lineItemSubscription, contentfulInterval) => {
    /* Removal from the cart is needed because the subscribeProduct function adds a product
      to the cart with the given subscription each time it's called.
      The updateSubscription sdk function requires both billing & shipping address and it's impossible to
      have them in the Product Details Page and the first steps of the Checkout for a first time,
      unauthenticated user.
    */

    //Retrieve cart lineItem based on the subscription sku
    const lineItem = cartItems.find(item => item.variant?.sku === lineItemSubscription.sku)
    if (lineItem !== null) {
      try {
        await chordCommerce.removeFromCart(lineItem.id)
        await chordCommerce.subscribeProduct({
          sku: lineItemSubscription.sku,
          quantity: contentfulInterval.length,
          interval: {
            unit: contentfulInterval.unit,
            length: contentfulInterval.length,
            name: contentfulInterval.name
          }
        })
      } catch (error) {}
    }
  }

  const copyOrder = () => {
    let urlParams = ''
    cartItems.map((item, index) => {
      urlParams = urlParams + `sku[${item.variant.sku}]=${item.quantity}`
      if (cartItems[index + 1]) {
        urlParams = urlParams + '&'
      }
    })
    navigator.clipboard.writeText(`${window.location.href}/share?${urlParams}`)
    setShowCopyMessage(true)
  }

  if (chordCommerce.cart.isFetching) {
    return <Loader />
  }

  function getSharedSKUs() {
    const sharedData = []
    for (const [skuData, quantity] of new URLSearchParams(parsedUrl.search).entries()) {
      const skuMatches = skuData.match(/sku\[(\d+)\]/)
      if (skuMatches?.length === 2) {
        sharedData.push({ sku: skuMatches[1], quantity: parseInt(quantity) })
      }
    }
    return sharedData
  }

  return (
    <div className="shopping-bag font-sfpro py-5">
      <div className="row mb-1">
        <div className="d-flex col-12">
          <h1 className="font-opensans-light fs-60 shopping-title">My Shopping Bag</h1>
          <FaShare className="fs-32 ms-3 mt-4 c-pointer" onClick={() => copyOrder()} />
          {showCopyMessage && (
            <span className="copied-msg px-2" onClick={() => setShowCopyMessage(false)}>
              Copied Your cart !!
            </span>
          )}
        </div>
      </div>
      {cartItems.map((cartItem, idx) => {
        return (
          <CartPageItem
            cartItem={cartItem}
            handleRemove={removeItem}
            handleQuantity={updateQuantity}
            handleSubscription={updateSubscription}
            key={cartItem.id + idx}
          />
        )
      })}
      <div className="row pt-4 border-top border-gray-200">
        <div className="col-md-5 offset-md-7 d-flex flex-column text-end">
          <div className="fs-20 item-total">{cartData.displayItemTotal}</div>
          {cartAmountAdjustments.length > 0 &&
            cartAmountAdjustments.map((adjustment, idx) => {
              if (adjustment.amount > 0) {
                return (
                  <div className="d-flex flex-row justify-content-end align-items-center" key={idx + adjustment.label}>
                    <span className="me-7">{adjustment.label}:</span>
                    <span className="fs-20">${adjustment.amount}</span>
                  </div>
                )
              }
            })}
          {promotions.map((adjustment, idx) => {
            return (
              <div className="d-flex flex-row justify-content-end align-items-center" key={idx + adjustment.id}>
                <span className="me-7">{adjustment.label}</span>
                <span className="fs-20">{adjustment.displayAmount}</span>
              </div>
            )
          })}
        </div>
      </div>
      <div className="col-md-4 offset-md-8 mb-5">
        <div className="mt-2 d-flex flex-row justify-content-between mb-2">
          <span className="me-7 d-flex flex-column  text-end">
            <strong className="fs-22 subtotal-title-num">Subtotal:</strong>
            <span className="lh-16 subtotal-content">Shipping and taxes will be calculated at checkout.</span>
          </span>
          <span className="fs-22 fw-bold subtotal-title-num">{cartData.displayTotal}</span>
        </div>
        <CartPromoCode />
      </div>
      <div className="row">
        <div className="col-md-3 fs-18 fw-bold">
          <Link to="/shop" className="btn btn-lg w-100 py-3 btn-outline-primary continue-shop-btn">
            CONTINUE SHOPPING
          </Link>
          <Link to="/checkout" className="btn btn-lg w-100 py-3 btn-primary mt-3 checkout-btn">
            PROCEED TO CHECKOUT
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CartPage
