import Dropdown from 'react-bootstrap/Dropdown'
import React, { useEffect, useState } from 'react'
import useSubscriptionsSettings from '~/hooks/graphql/queries/use-subscription-settings'
import PropTypes from 'prop-types'

const CartQuantitySubscription = ({ sku, cartSubscription, handleSubscription }) => {
  const products = useSubscriptionsSettings()
  const [selectedInterval, setSelectedInterval] = useState(null)
  const [productSubscription, setProductSubscription] = useState(null)

  useEffect(() => {
    if (productSubscription !== null) {
      productSubscription.intervals.map(interval => {
        if (isSelected(interval)) {
          setSelectedInterval(interval)
        }
        return interval
      })
    }
    return () => {
      setSelectedInterval(null)
    }
  }, [productSubscription])

  useEffect(() => {
    products.map(product => {
      if (product.sku === sku) {
        setProductSubscription(product.subscription)
      }
      return product
    })

    return () => {
      setProductSubscription(null)
    }
  }, [])

  const updateSubscription = interval => {
    setSelectedInterval(interval)
    handleSubscription(cartSubscription, interval)
  }
  const isSelected = interval => {
    return (
      cartSubscription.intervalLength === interval.length && interval.unit.indexOf(cartSubscription.intervalUnits) > -1
    )
  }

  return (
    <>
      <div className="mb-3 fw-bold">SUB.</div>
      <Dropdown className="custom-dropdown">
        {selectedInterval !== null && (
          <Dropdown.Toggle className="px-4 border border-gray-400 border rounded-0 bg-transparent txt-gray-900">
            {selectedInterval.name}
          </Dropdown.Toggle>
        )}

        {productSubscription !== null && (
          <Dropdown.Menu>
            {productSubscription.intervals.map(interval => {
              return (
                <Dropdown.Item
                  key={interval.id}
                  active={isSelected(interval)}
                  onClick={() => updateSubscription(interval)}
                  className="d-flex align-items-center justify-content-center"
                >
                  {interval.name}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
CartQuantitySubscription.propTypes = {
  sku: PropTypes.string.isRequired,
  cartSubscription: PropTypes.shape({
    intervalLength: PropTypes.number.isRequired,
    intervalUnits: PropTypes.array.isRequired
  }).isRequired,
  handleSubscription: PropTypes.func.isRequired
}
export default CartQuantitySubscription
