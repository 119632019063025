import React, { useEffect, useState } from 'react'
import CartQuantityProduct from '~/components/Cart/CartQuantityProduct'
import CartQuantitySubscription from '~/components/Cart/CartQuantitySubscription'
import { getProductSubscription } from '~/utils/cart'
import ChordServices from '~/api/chord-services'
import { REQ_STATUS, MOBILE_WIDTH } from '~/config'

const CartPageItem = ({ cartItem, handleRemove, handleQuantity, handleSubscription }) => {
  const [statusFetch, setStatusFetch] = useState(REQ_STATUS.IDLE)
  const [subscription, setSubscription] = useState(null)
  const [productIsKit, setProductIsKit] = useState(false)
  const product = cartItem.variant
  const [screenWidth, setSecreenWidth] = useState(window.innerWidth)
  const updateDimensions = () => {
    setSecreenWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    setStatusFetch(REQ_STATUS.PENDING)

    //Since the cartItem can have multiple subscriptionLineItems, search by product sku
    if (cartItem.variant !== null && cartItem.subscriptionLineItems.length > 0) {
      setSubscription(getProductSubscription(cartItem.variant.sku, cartItem.subscriptionLineItems))
    } else {
      //If is not a subscription check if is productKit
      checkProductIsKit()
    }
    setStatusFetch(REQ_STATUS.RESOLVED)
    return () => {
      setSubscription(null)
      setStatusFetch(REQ_STATUS.IDLE)
    }
  }, [cartItem])

  const checkProductIsKit = async () => {
    const isKit = await ChordServices.isProductKit(product.productId)
    setProductIsKit(isKit)
  }

  const removeItem = id => {
    handleRemove(id)
  }

  if (product === null) {
    return <div>No product for this Cart Item</div>
  }

  return (
    <div className="cart_item d-flex py-2 border-bottom border-gray-200">
      <div className="col-sm-10 col-md-5 d-flex">
        <div className="col-sm-4 prod-img me-2 ">
          {product.images.length > 0 && (
            <img src={product.images[0].smallUrl} alt={product.name} className="img-fluid" />
          )}
        </div>
        <div className=" col-sm-7 prod-info d-flex flex-column">
          <div className="d-flex flex-column">
            <h3 className="m-0 cart_name">{product.name}</h3>
            <span className="cart_disc">{product.description}</span>
            {subscription !== null && (
              <span className="mt-2">
                <strong>SUBSCRIPTION:</strong> renews every {subscription.interval}
              </span>
            )}
          </div>
          <div className="fs-18">
            <span
              onClick={() => removeItem(cartItem.id)}
              onKeyDown={() => removeItem(cartItem.id)}
              aria-hidden
              className="c-pointer cart_remove text-decoration-underline "
            >
              remove
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-3 d-flex flex-row justify-content-between">
        {screenWidth > MOBILE_WIDTH && (
          <div className="col-md-5 d-flex flex-row justify-content-between">
            <div className="d-flex flex-column">
              {subscription !== null && (
                <CartQuantitySubscription
                  sku={product.sku}
                  cartSubscription={subscription}
                  handleSubscription={handleSubscription}
                />
              )}
              {subscription === null && !productIsKit && statusFetch === REQ_STATUS.RESOLVED && (
                <CartQuantityProduct cartItem={cartItem} handleQuantity={handleQuantity} />
              )}
            </div>
          </div>
        )}
        <div className="d-flex flex-column">
          <p className="mb-3 fw-bold">UNIT PRICE</p>
          <span>{cartItem.singleDisplayAmount}</span>
        </div>
      </div>
      <div className="col-md-2 d-flex flex-column text-end">
        <p className="d-none d-md-block mb-3 fw-bold">TOTAL</p>
        <span className="cart_price mb-3">{cartItem.displayAmount}</span>
        {screenWidth < MOBILE_WIDTH && (
          <div className="col-md-5 d-flex flex-row justify-content-between">
            <div className="d-flex flex-column">
              {subscription !== null && (
                <CartQuantitySubscription
                  sku={product.sku}
                  cartSubscription={subscription}
                  handleSubscription={handleSubscription}
                />
              )}
              {subscription === null && !productIsKit && statusFetch === REQ_STATUS.RESOLVED && (
                <CartQuantityProduct cartItem={cartItem} handleQuantity={handleQuantity} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CartPageItem
